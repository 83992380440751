import React, {useCallback, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Routes, Route, useLocation} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { config } from './config.js';
import 'antd/dist/antd.less';

import './assets/fonts/Heebo/heebo.css';

import Course from "./pages/course/Course";

import translationsHe from "./translations/translationsHe";
import translationsAr from "./translations/translationsAr";
import {updateTranslations} from "./store/config";
import {updateConfig} from "./store/config";
import {ConfigProvider} from "antd";
import appConfig from "./appConfig";
import {RootState} from "./store/store";
import MainPage from './pages/products/MainPage';
import SingleProduct from './pages/single-product/single-product/SingleProduct';
import ProductHeader from './components/ProductHeader/ProductHeader';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import PrivateFlights from './pages/private-flights/PrivateFlights';
import he from "antd/es/locale/he_IL";
import useSiteLang from "./translations/useSiteLang";
import Training from "./pages/training/Training";
import MainPage2 from "./pages/main-page/main-page";
import Webinar from "./pages/webinar/Webinar";
// import {Sky360MainPage} from "./pages/sky360/screens/Desktop";
import { Sky360LandingPage } from "./pages/sky360/screens/Desktop";
import LeadLandingPage from "./pages/lead-landing-page/LeadLandingPage";

const queryClient = new QueryClient();

function App() {
    const dispatch = useDispatch();
    const location = useLocation();

    const getConfig = useCallback(
        async () => {
            // const appConfig = response.data?.data;
            dispatch(updateConfig(appConfig));
            // dispatch(updateConfig(appConfig));
            ConfigProvider.config({
                theme:{
                    primaryColor:appConfig.theme.primary,
                }
            });
        },
        [dispatch],
    );

    const {isTranLoaded, direction} = useSiteLang({ siteConfig: appConfig.siteConfig});

    const getLayout: any = () => {
        if (!(
            location.pathname.startsWith('/webinar') ||
            location.pathname.startsWith('/course') ||
            location.pathname.startsWith('/training')))
            return <ProductHeader />;
        return null;
    }

    return (
        <ConfigProvider direction={direction} locale={he}>
        <div className="app" dir={direction}>
                {
                    !isTranLoaded ? "" :
                        <QueryClientProvider client={queryClient}>
                            <ScrollToTop />
                            {/*{getLayout()}*/}
                            <Routes>
                                {/*<Route path="/" element={<MainPage />} />*/}
                                <Route path="/" element={<Sky360LandingPage />} />
                                {/*<Route path="/" element={<MainPage2 />} />*/}
                                <Route path="/:name" element={<SingleProduct />} />
                                <Route path='/private-flights' element={<PrivateFlights />}/>
                                <Route path='/course-reg/:courseKey' element={<Course />}/>
                                <Route path='/course-reg/:courseKey/:aff' element={<Course />}/>


                                <Route path='/course/:courseKey/:aff' element={<Training />}/>
                                <Route path='/course/:courseKey' element={<Training />}/>
                                <Route path='/course-pay' element={<Training />}/>


                                <Route path='/webinar/:courseKey/:aff' element={<Webinar />}/>
                                <Route path='/webinar/:courseKey' element={<Webinar />}/>
                                <Route path='/webinar' element={<Webinar />}/>

                                <Route path='/reg/:courseKey' element={<LeadLandingPage />}/>
                                <Route path='/reg/:courseKey/:aff' element={<LeadLandingPage />}/>

                                <Route path='/lead/:courseKey' element={<LeadLandingPage />}/>
                                <Route path='/laed/:courseKey/:aff' element={<LeadLandingPage />}/>
                            </Routes>
                            <Routes>
                            </Routes>
                        </QueryClientProvider>
                }
            </div>
        </ConfigProvider>
    );
}

export default App;
