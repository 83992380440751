import {memo, useEffect, useState} from "react";
import apiClient from '@skygroup/shared/API/apiClient';
import {useQuery} from "@tanstack/react-query";

export const useCourseCycles = (id = "") => {
    const COURSE_CYCLES_API = "b2c/lead/courseCycles";

    const { isLoading, isError, error, data, refetch, isFetching }: any =
        useQuery(
            [`courses`],
            async () => {
                const result = await apiClient.get(id ? COURSE_CYCLES_API + "?id=" +id : COURSE_CYCLES_API);
                return result.data.data.cycles;
            },
            {
                refetchOnWindowFocus: false,
                retry: 2,
                cacheTime: 0,
            }
        );

    return {
        isLoading,
        isError,
        error,
        data,
        refetch,
        isFetching,
    };
}